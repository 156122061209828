<template>
  <v-text-field
    autocomplete="off"
    class="validation"
    clear-icon="$delete"
    :clearable="!readonly"
    :counter="counter"
    :label="$t('label')"
    :readonly="readonly"
    :rules="rules"
    :value="value"
    @input="input"
  ></v-text-field>
</template>

<script>
const CONFERENCE_SUBJECT_MAX_LENGTH = 50;
export default {
  name: "ConferenceSubjectInput",
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  data() {
    return {
      counter: CONFERENCE_SUBJECT_MAX_LENGTH,
      rules: [
        (v) => (v && v.length > 0) || this.$t("validation.required"),
        (v) =>
          (v && v.length <= CONFERENCE_SUBJECT_MAX_LENGTH) ||
          this.$t("validation.tooLong"),
        (v) =>
          (v && /^[^?&:"'%#]+$/.test(v)) ||
          this.$t("validation.invalidCharacters"),
      ],
    };
  },
  methods: {
    input(event) {
      this.$emit("input", event);
    },
  },
  i18n: {
    messages: {
      de: {
        label: "Name der Konferenz",
        validation: {
          required: "Bitte geben Sie einen Namen für Ihre Konferenz ein",
          tooLong: "Der Konferenzname ist zu lang",
          invalidCharacters:
            "Der Konferenzname sollte keines der folgenden Zeichen beinhalten: ?, &, :, ', \", %, #",
        },
      },
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
</style>
